@media screen and (max-width: 1400px) {
    .quiz-mike {
        position: absolute;
        right: -50px;
        bottom: -50px;
        width: 260px;
    }

    .leadeBlock:before {
        top: -1px;
        right: -2px;
    }

    .past-activit-one img {
        height: 170px;
        width: 160px;
    }

    .past-activit-one span {
        display: flex;
        align-items: center;
        font-size: 14px;
    }

    .Path-to-enginnerTabs .tab-content .engCareerPathBlock:after {
        background-image: none;
    }

}

@media screen and (max-width: 1200px) {
    main {
        background: none;
    }

    .onboarding-main {
        height: 920px;
    }

    .onboarding-info .users img {
        width: 152px;
        height: 152px;
    }

    .onboarding-info .users .user-name {
        font-size: 18px;
        margin-top: 10px;
    }

    .user-info-add .add-user {
        width: 152px;
        height: 152px;
        border-radius: 28px;
    }

    .user-info-add .add-user img {
        width: 36px;
        height: 36px;
        margin-bottom: 10px;
        margin-top: 45px;
    }

    .user-info-add .add-user .add-account {
        font-size: 16px;
    }

    .onboarding-main:before {
        width: 450px;
        height: 120px;
    }

    .onboarding-bottom-content h2 {
        font-size: 28px;
    }

    .left-navigation .nav-list-bar {
        padding-left: 15px;
    }

    .left-nav-carousel .need-help {
        width: 80%;
    }

    .report-img {
        width: 300px;
    }

    .explore-report h2 {
        font-size: 20px;
        line-height: 25px;
    }

    .progress-path .step-one img {
        width: 100px;
    }

    .progress-path .step-one {
        width: 110px;
    }

    .progress-path .step-one h3 {
        font-size: 16px;
    }

    .quiz-head .progress-sec {
        min-width: 500px;
    }

    .tab-content-development .nav-tabs .nav-link img {
        width: 30px;
        height: 30px;
        margin-right: 4px;
    }

    .leadership-slide img {
        height: 150px;
    }

    .leadeBlock:before {
        top: -1px;
        right: -1px;
    }

    .celeb-img {
        width: auto;
    }

    .superpowerBlock p span {
        font-size: 38px;
    }

    .superpowerBlock p {
        margin-bottom: 20px;
        font-size: 20px;
    }

    .past-activit-one .date-top-right {
        padding: 10px 10px 10px 0px;
        width: 160px;
        font-size: 12px;
    }

    .past-activit-one img {
        height: 140px;
        width: 120px;
    }

    .Path-to-enginnerTabs .tab-content .engCareerPathBlock {
        margin-bottom: 20px;
    }

    .Spacebugs-right-img img {
        /* max-width: 250px; */
    }

    .Spacebugs-right-img span {
        font-size: 26px;
    }

}

@media screen and (max-width: 1024px) {

    .ShapesBlock-right {
        display: block;
        position: relative;
    }

    .eng-CompanyData-three-columns>* {
        margin: 0 14px 0 14px;
        width: calc(88% / 3);
    }

    .text-time, .text-time-date {
        font-size: 14px;
    }

    .future-step-1 button, .future-step-2 button, .future-step-3 button {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .future-step-1, .future-step-2, .future-step-3, .congrats-step {
        min-height: 320px;
    }

    .report-img {
        width: 220px;
    }

    .explore-report-ready {
        height: 130px;
        margin-top: 35px;
    }

    .webinar-name h3 {
        font-size: 16px;
    }

    .workshop-img img {
        height: 220px;
    }

    .progress-path .step-one img {
        width: 80px;
    }

    .progress-path .step-one {
        width: 90px;
    }

    .progress-path .step-one .check-progress {
        width: 42px;
        font-size: 26px;
        height: 28px;
    }

    .non-active .bi-lock {
        font-size: 16px;
        top: 5px;
    }

    .step-one:after {
        /* left: 60px; */
        top: 28%;
    }

    .quiz-head .progress-sec {
        min-width: 350px;
    }

    .tab-content-development .nav-tabs .nav-link {
        padding: 18px 0px 18px 8px;
        font-size: 14px;
        text-align: center;
    }

    .leadeBlock:before {
        top: -1px;
        right: -2px;
    }

    .leaderbulp-img {
        top: -50px;
        border-radius: 0 0 0 0 !important;
    }

    .leaders-content {
        padding: 40px 0;
    }

    .activityIcon-label label {
        margin-left: 20px;
    }

    .superpowerBlock {
        min-height: 480px;
        display: block;
    }

    .want-study-abroad h3 {
        font-size: 18px;
        line-height: 25px;
    }

    .CareerOptions-Tabs .card-header-tabs .nav-link {
        padding: 8px 4px;
    }

    .package-icon {
        padding: 8px 34px;
    }

    .CareerOptions-Tabs-type2 ul.nav.card-header-tabs {
        justify-content: flex-start;
    }

    .CareerOptions-Tabs-type2 .tab-header ul li:last-of-type {
        /* padding: 16px 0 0 24px; */
    }
}

@media screen and (max-width: 992px) {
    .mobile-assiment-dc{
        display: none !important;
    }

    .outer-c-thead .c-thead {
        margin-bottom: 10px;
        margin-top: 40px;
    }

    .c-user-rane {
        display: flex;
        margin-bottom: 10px;
        align-items: center;
    }

    .timeline li {
        font-size: 12px;
        padding-top: 10px;
    }

    .timeline li:before {
        width: 25px;
        height: 25px;
        top: -6px;
    }

    .c-thead span {
        font-weight: 600;
    }

    .eng-CompanyData-three-columns>* {
        margin: 0 14px 0 14px;
        width: calc(90% / 2);
    }

    section.civil_engineering_JobType {
        padding: 64px 32px;
    }

    .Webinar-sec {
        background: #472AB1;
        border-radius: 48px;
        color: #ffffff;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }

    .rounded-rectangle {
        top: 30px;
        left: 50%;
        transform: rotate(-12deg);
        z-index: 99;
    }

    .Superpowers-WebinarBlock {
        padding-top: 50px;
    }

    .enginnering-career-detail div {
        align-items: center;
    }

    .left-navigation .nav-list-bar li a {
        font-size: 14px;
        margin: 5px 0;
        padding: 14px 5px 14px 55px;
    }

    .left-navigation .nav-list-bar {
        padding-left: 5px;
    }

    .left-navigation .logo {
        width: 140px;
        margin-bottom: 20px;
    }

    .left-navigation {
        min-height: 1000px;
        border-radius: 20px;
    }

    .nav-link::before {
        left: 18px;
    }

    .active .nav-link::before {
        left: 18px;
    }

    .nav-list-bar li:hover .nav-link:before {
        left: 18px !important;
    }

    .navbar-toggler:focus {
        outline: none;
    }

    .right-content {
        padding: 25px 30px;
    }

    .welcome-user h2 {
        font-size: 20px;
    }

    .welcome-user span {
        font-size: 18px;
    }

    .notification img {
        width: 42px;
        height: 42px;
    }

    .user-details img {
        width: 42px;
        height: 42px;
    }

    .star-points img {
        width: 42px;
        height: 42px;
    }

    .star-points span {
        font-size: 14px;
    }

    .notification-alert {
        width: 10px;
        height: 10px;
        top: 2px;
        right: 12px;
    }

    .popup-user {
        position: absolute;
        right: 35px;
        top: 92px;
        background-color: #EBF5FF;
        padding: 10px 20px;
        width: 230px;
        border-radius: 25px;
        width: 202px;
    }

    .notification-popup {
        position: absolute;
        right: 90px;
        top: 92px;
        width: 400px;
        border-radius: 25px;
        padding: 20px 10px 10px 10px;
    }

    .popup-user .profile-icon {
        font-size: 16px;
        font-weight: 600;
    }

    .popup-user .logout {
        font-size: 16px;
        font-weight: 600;
    }

    .popup-user ul li {
        padding: 5px 0;
    }

    .popup-user img {
        width: 30px;
        height: 30px;
    }

    .notification-info h2 {
        font-size: 16px;
        line-height: 22px;
    }

    .notification-info p {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 0px;
    }

    .notification-info span {
        font-size: 12px;
    }

    .text-time {
        padding: 12px 25px 12px 90px;
    }

    .future-step-1 button, .future-step-2 button, .future-step-3 button {
        font-size: 16px;
    }

    .explore-acadspace h2 {
        font-size: 20px;
        line-height: 32px;
    }

    .explore-acadspace p {
        font-size: 16px;
        font-weight: 400;
    }

    .btn-take-tour {
        font-size: 16px;
        font-weight: 600;
    }

    .congrats-step img {
        width: 50%;
    }

    .explore-report h2 {
        font-size: 18px;
        line-height: 25px;
    }

    .section-header h2 {
        font-size: 18px;
    }

    .section-header a {
        font-size: 16px;
    }

    .spacebugs-popup-flex {
        display: block;
    }

    .spacebugs-popup-flex img {
        height: 280px;
        border-radius: 35px 35px 0px 0px;
        width: 100%;
        object-fit: cover;
        object-position: top;
    }

    .spacebugs-popup-content {
        padding: 40px 30px;
    }

    .spacebugs-popup {
        border-radius: 40px;
    }

    .modal .btn-close {
        right: -5px;
    }

    .quiz-head .progress-sec {
        min-width: 300px;
    }

    .quiz-main {
        padding: 20px !important;
    }

    .quiz-mike {
        position: absolute;
        right: -20px;
        bottom: -20px;
        width: 200px;
    }

    .question-number {
        left: 0px;
        margin-top: 30px;
    }

    .question-number h2 {
        margin-right: 15px;
        left: 0;
    }

    .tab-content-development-inner .carousel-control-prev, .carousel-control-next {
        width: 60%;
    }

    .tab-content-development-inner h2 {
        font-size: 16px;
        line-height: 22px;
    }

    .leadeBlock:before {
        top: -1px;
        right: -1px;
    }

    .explore-card-content {
        padding: 65px 0 30px;
    }

    .superpower-content {
        padding-bottom: 250px;
    }

    .activities-exercies::before {
        top: -6%;
    }

    .activities-exercies::after {
        top: -6%;
    }

    .Famous-celeb::before {
        top: -6%;
    }

    .Famous-celeb::after {
        top: -6%;
    }

    .curted-cards::before {
        top: -6%;
    }

    .curted-cards::after {
        top: -6%;
    }

    .top-scolership::before {
        top: -10%;
    }

    .top-scolership::after {
        top: -10%;
    }

    .CareerOptions-Tabs::before {
        top: -8%;
    }

    .CareerOptions-Tabs::after {
        top: -8%;
    }

    .keyskills::before {
        top: -10%;
    }

    .keyskills::after {
        top: -10%;
    }

    .superpower-img .Scoial {
        background-image: url(../images/Polygon.png);
        font-size: 16px;
        width: 140px;
        height: 98px;
        bottom: 0px;
        right: 160px;
    }

    .superpower-img .Investigative {
        font-size: 16px;
        width: 160px;
        display: flex;
        height: 68px;
        right: 20px;
        bottom: 0px;
    }

    .superpower-img .Artistic {
        background-color: #FFB800;
        font-size: 16px;
        width: 140px;
        height: 105px;
        right: 98px;
        top: -182px;
        transform: rotate(21deg);
    }

    .skillsIcon label {
        margin-bottom: 30px;
        font-size: 14px;
    }

    .skillsIcon img {
        padding: 15px;
        width: 90px;
    }

    .engineering-stremsBlock label {
        min-height: 80px;
        display: flex;
        align-items: center;
    }

    .dreamCareer img {
        position: relative;
        bottom: unset;
    }

    .engineering-stremsBlock {
        margin-bottom: 100px;
        padding: 70px 20px 24px;
    }

    .scolership {
        margin-bottom: 50px;
    }

    .activity-earrning {
        word-break: break-word;
        white-space: initial;
        display: inline-block;
        padding: 20px 0 0 0;
    }

    .today-activities-heading, .activity-happenon {
        margin-bottom: 32px;
    }

    .vedantu-career div {
        align-items: center;
    }

    .vedantu-career heading {
        line-height: 32px;
    }

    .vedantu-career p {
        line-height: 52px;
    }

    .hours-rating, .price-buy-block {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;
    }

    .hours-rating {
        margin: 20px 0;
    }

    .new-old-price {
        margin-bottom: 20px;
    }

    .lesson-basic-destails h4 {
        margin-bottom: 10px;
    }

    .instructor-basicdetail {
        display: inline-block;
    }

    .instructor-basicdetail ul {
        align-items: start;
        padding: 0 50px 0 0;
        display: flex;
        justify-content: space-between;
    }

    .instructor-basicdetail ul li {
        padding: 0%;
    }

    .CareerOptions-Tabs-type2 .tab-header ul li:last-of-type {
        /* display: block; */
        /* width: 100%; */
    }

    .user-profile-bulb img {
        position: relative;
        bottom: 0;
        left: 25px;
        height: 160px;
    }

    .user-profile-content .profile-sec {
        justify-content: flex-start;
        gap: 10px;
    }

    .user-profile-content .profile-sec img {
        width: 100px;
        height: 100px;
        object-fit: cover;
        margin-right: 20px;
    }

    .user-profile-details-info h2 {
        font-size: 18px;
        margin-bottom: 0px;
    }

    .user-profile-details-info p {
        font-size: 16px;
        margin-bottom: 0px;
    }

    .choose-file-profile .choose-file {
        width: 30px;
        height: 30px;
        right: 15px;
    }

    .Spacebugs-right-img {
        justify-content: center;
        min-width: 240px;
        min-height: 330px;
    }

    .Spacebugs-right-img span {
        font-size: 26px;
        text-align: center;
        padding-top: 70px;
    }

    .spacebug-popup .modal-body{
        display: block;
    }
    
    .spacebug-popup .modal-body img{
        border-radius: 28px 28px 0px 0px;
        object-fit: cover;
        height: 200px;
        width: 100%;
        object-position: 0px -95px;
    }

    .spacebug-popup{
        border-radius: 30px !important;
    }
    
    .spacebug-content-right {
        padding: 25px;
    }
    .activity_Block {
        margin-bottom: 20px;
    }

    .counsellingDetails {
        line-height: normal;
        padding-left: 0;
        border-top: 1px solid #ccc;
        margin-top: 20px;
        padding-top: 20px;
    }
    
    .counsellingDetails p {
        padding: 10px 0;
    }
    .CounsellorProfileDetails h5 {
        margin-bottom: 16px;
    }
    .CounsellorProfileDetails .profile {
        padding-right: 0;
        border: none;
    }
    .CounsellorProfileDetails .profile br {
        display: none;
    }
    .CounsellorProfileDetails {
        flex-direction: column;
        margin-top: 20px;
    }
    .CounsellorProfile {
        flex-direction: column;
    }
    
}

@media screen and (max-width: 820px) {
    .activityIcon-label label {
        max-width: 100%;
    }

    .explore-card-content {
        padding: 80px 0 40px 0;
    }
}

@media screen and (max-width: 767px) {

    .navbar-collapse {
        flex-basis: 100%;
        flex-grow: 1;
        align-items: center;
        position: absolute;
        width: 280px;
        z-index: 99;
        top: 60px;
        left: -280px;
        transition: .36s ease-in-out ;
    }

    .left-navigation .show{
        left: 0;
        width: 280px;
        height: unset;
        transition: .36s ease-in-out;
        z-index: 9999;
    }

    .navcllaps{
        position: absolute;
        right: -10px;
        top: -30px;
        width: 36px;
        display: block;
        height: 36px;
        text-align: center;
        border-radius: 50%;
        background-image: url(../images/add-icon.png);
        background-size: contain;
        border: 1px solid rgb(0, 122, 179);
        transform:rotate(45deg);
        z-index: 9;
    }

    .notification-popup .handle-close{
        width: 30px;
        height: 30px;
    }

    .popup-user .handle-close{
        width: 30px;
        height: 30px;
    }

    .eng-CompanyData-three-columns>* {
        width: calc(86% / 2);
    }

    .Webinar-sec {
        padding: 60px 30px 0 30px;
    }

    .Superpowers-WebinarBlock h4 {
        text-wrap: wrap;
    }

    .Superpowers-WebinarBlock p {
        font-weight: bold;
        margin-top: 30px;
    }

    .Superpowers-WebinarBlock {
        padding: 0;
    }

    .ShapesBlock-right {
        display: block;
        position: relative;
        right: 0;
    }

    .oval {
        padding: 36px 40px;
        position: relative;
        right: -8px;
    }

    .triangle {
        padding: 0 60px;
        height: 133px;
        background-size: contain;
    }

    .triangle p {
        top: 15%;
        font-size: 23px;
    }

    .rounded-rectangle {
        top: 55px;
        left: 111px;
    }

    .enginnering-career-detail {
        display: block;
        text-align: center;
    }

    .enginnering-career-detail {
        background-color: #001E3A;
        height: auto;
        border-radius: 32px;
    }

    .enginnering-career-detail img {
        margin-bottom: 20px;
        max-width: 120px;
    }

    .enginnering-career-detail heading {
        font-size: 28px;
        padding-bottom: 26px;
    }

    .engineering-videos iframe {
        margin-bottom: 20px;
    }

    .engCareer-1 div {
        align-items: center;
    }

    .Eng-college-name {
        min-height: auto;
    }

    ::-webkit-scrollbar {
        width: 6px;
    }

    .main-dashboard-padding {
        padding: 0 15px;
    }

    .onboarding-main {
        height: auto;
        padding-bottom: 150px;
        background-position: 15% top;
    }

    .onboarding-main:before {
        width: 100%;
        background-image: unset;
        background-color: white;
        position: absolute;
        height: 85px;
    }

    .onboarding-info h1 {
        font-size: 28px;
        line-height: 40px;
    }

    .onboarding-info p {
        font-size: 18px;
    }

    .onboarding-info span {
        font-size: 16px;
    }

    .onboarding-info a {
        font-size: 18px;
    }

    .onboarding-bottom-content h2 {
        font-size: 22px;
    }

    .onboarding-bottom-content {
        /* left: unset; */
        position: absolute;
    }

    .onboarding-info {
        margin-top: 20px;
    }

    .onboarding-info .users {
        margin: 35px 0;
    }

    .onboarding-info .users img {
        width: 100px;
        height: 100px;
    }

    .user-info-add .add-user {
        width: 100px;
        height: 100px;
    }

    .user-info-add .add-user img {
        width: 26px;
        height: 26px;
        margin-bottom: 10px;
        margin-top: 25px;
    }

    .user-info-add .add-user .add-account {
        font-size: 14px;
        line-height: 10px;
    }

    .onboarding-info .users .user-name {
        font-size: 14px;
        margin-top: 5px;
    }

    .create-account-flex {
        margin: 10px 0 20px 0;
    }

    .create-account-middle-content {
        padding: 40px 35px 45px 35px;
    }

    .create-account-form .input {
        width: 100%;
        height: 50px;
    }

    .create-account-form label {
        font-size: 16px;
    }

    .create-account-form .otpinput {
        width: 35px;
        height: 46px;
        margin: 10px 2px 50px 2px;
        border-radius: 10px;
    }

    .main {
        display: block;
    }

    .left-navigation .navbar .navbar-brand {
        display: unset;
    }

    .left-navigation {
        background-color: transparent;
        width: 100%;
        min-height: auto;
    }

    .left-navigation .logo {
        width: 110px;
        margin-bottom: 0;
        margin-left: 0px;
    }

    .left-navigation .nav-list-bar {
        background-color: #EBF5FF;
        padding: 15px;
        margin-top: 15px;
        margin-bottom: 0;
        height: 100vh;
        top: -15px;
        position: fixed;
        width: 270px;
    }

    .left-navigation .nav-list-bar li.active {
        border-radius: 50px;
    }

    .left-navigation .nav-list-bar li:hover {
        border-radius: 50px;
    }

    .nav-list-bar .nav-item.active::before {
        display: none;
    }

    .nav-list-bar .nav-item.active::after {
        display: none;
    }

    .navbar-toggler {
        border: none !important;
        position: relative;
        top: 0px;
        outline: none !important;
        padding: 2px 4px;
        margin-left: 0px;
        color: transparent;
    }

    .navbar-toggler:focus {
        box-shadow: none !important;
    }

    .right-content {
        width: 100%;
        background-color: #ffffff;
        padding: 15px 0px 20px 0px;
        margin-top: 5px;
    }

    .main-nav-top {
        display: block;
        padding: 0 15px;
        margin-bottom: 25px;
    }

    .user-all-details {
        gap: 10px;
        position: absolute;
        top: 10px;
        right: 10px;
    }

    .left-navigation .navbar {
        padding: 5px 0px;
        display: flex;
        justify-content: flex-end;
        flex-direction: row-reverse;
    }

    .left-navigation .nav-list-bar li a {
        padding: 12px 5px 12px 60px;
    }

    .total-count {
        position: absolute;
        background-color: #001E3A;
        font-size: 9px;
        border-radius: 15px;
        padding: 0 8px;
        color: #ffffff;
        top: 37px;
        font-weight: 500;
        line-height: 20px;
    }

    .user-all-details .star-points {
        position: relative;
        padding: 0;
    }

    .star-points span {
        justify-content: center;
        display: flex;
    }

    .popup-user {
        position: absolute;
        right: 5px;
        top: 65px;
        background-color: #EBF5FF;
        padding: 10px 20px;
        width: 230px;
        border-radius: 25px;
        width: 202px;
    }

    .notification-popup {
        position: absolute;
        right: 5px;
        top: 65px;
        width: 300px;
    }

    .notification-popup li {
        padding-right: 10px;
    }

    .notification-info h2 {
        font-size: 14px;
        line-height: 20px;
    }

    .notification-info p {
        font-size: 12px;
        line-height: 16px;
    }

    .notification-info span {
        font-size: 10px;
    }

    .left-nav-carousel {
        /* display: none; */
    }

    .btn-take-tour {
        margin-bottom: 15px;
        width: 160px;
        height: 50px;
    }

    .explore-card {
        text-align: center;
        margin-bottom: 45px;
        height: auto;
        margin-top: 10px;
    }

    .future-steps {
        margin-bottom: 25px;
    }

    .webinar {
        margin-bottom: 40px;
    }

    .section-header h2 {
        font-size: 18px;
    }

    .workshop-img img {
        height: 260px;
    }

    .workshop {
        margin-bottom: 20px;
    }

    .webinar-img .webinar-banner-img {
        width: 100%;
        height: 200px;
        object-fit: fill;
    }

    .report-img {
        position: relative;
        height: 130px;
        bottom: -22px;
    }

    .quiz-mike {
        width: 120px;
    }

    .quiz-head {
        display: block;
    }

    .quiz-head .progress-sec {
        min-width: auto;
        left: 0px;
        top: 55px;
    }

    .button-next button {
        width: 120px;
        height: 52px;
        margin-right: 15px;
        margin-top: 10px;
    }

    .middle-quiz-sec p {
        font-size: 22px;
        line-height: normal;
    }

    .middle-quiz-sec h2 {
        font-size: 30px;
        margin-bottom: 0px;
    }

    .question-number {
        margin-top: 120px;
    }

    .qustion-row .ans-col {
        font-size: 16px;
        line-height: 22px;
        padding: 10px 15px 10px 5px;
    }

    .button-next a {
        font-size: 18px;
    }

    .next-slide-quiz {
        position: relative;
        margin-top: 20px;
    }

    .leadeBlock {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        margin-top: 60px;
    }

    .leaderbulp-img {
        position: absolute;
    }

    .leaders-content {
        padding: 190px 40px 40px;
        z-index: 9;
    }

    .superpowerBlock {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        /* padding: 56px 10px 56px 20px; */
        padding: 70px 10px 0px 20px;
    }

    .superpower-img {
        padding: 0 90px 0;
    }

    .activities-exercies .Sec-HeadingBlock {
        flex-direction: column;
    }

    .Sec-HeadingBlock a {
        margin-bottom: 20px;
        line-height: 20px;
        font-size: 16px;
    }

    .activityIcon-label label {
        max-width: 250px;
    }

    .superpowerBlock .superpower-content {
        flex-direction: column;
        display: flex;
    }

    .explore-card-content {
        padding: 0px 20px 30px 40px;
    }

    .Famous-celebBlock {
        margin-bottom: 30px;
    }

    .activities-exercies {
        padding: 56px 30px;
    }

    .Famous-celeb {
        padding: 56px 30px;
    }

    .curted-cards {
        padding: 50px 20px;
    }

    .top-scolership {
        padding: 60px 20px 50px 20px;
    }

    .study-abroad {
        min-height: auto;
    }

    .study-abroad img {
        width: 180px;
        height: 180px;
        object-fit: contain;
    }

    .study-abroad button {
        margin-bottom: 20px;
    }

    .CareerOptions-Tabs .tab-header ul li {
        display: contents;
    }

    .CareerStreams .Sec-HeadingBlock {
        flex-direction: column-reverse;
    }

    .streamslist ul.list li:first-child, .streamslist ul.list li:nth-child(2), .streamslist ul.list li:nth-child(3) {
        padding-top: 32px;
    }

    .streamslist ul.list li {
        width: 45%;
    }

    .tab-grad-img {
        width: 50%;
    }

    .CareerOptions-Tabs .cardTab-Heading span {
        font-size: 36px;
    }

    .CareerOptions-Tabs {
        padding: 56px 30px;
    }

    .keyskills {
        padding: 56px 15px;
    }

    .multiple-skillset ul {
        display: block;
    }

    .multiple-skillset label {
        display: block;
    }

    .multiple-skillset .skillsIcon {
        width: 35%;
        display: block;
    }

    .CareerStreams .Sec-HeadingBlock {
        margin-bottom: 10px;
    }

    .CareerStreams {
        padding: 40px 35px 20px 35px;
    }

    .want-study-abroad {
        padding: 40px 40px 0;
    }

    .want-study-abroad img {
        position: relative;
        bottom: 0;
        margin: 0 auto;
        display: block;
    }

    .Topengineering-strems .Sec-HeadingBlock {
        display: inline-block;
        margin-bottom: 100px;
        line-height: 42px;
    }

    .blogpost-1 {
        margin-bottom: 40px;
    }

    .lesson-basic-destails {
        margin-top: 40px;
    }

    .lesson-listBlock label {
        flex-direction: column;
        line-height: 40px;
    }

    .today-activities-heading {
        line-height: 48px;
    }


    .instructor-block {
        flex-direction: column-reverse;
    }

    .what-student-says {
        margin-bottom: 20px;
    }

    .about-instructor img {
        margin-bottom: 20px;
    }

    .user-profile-details-info:before {
        border-width: 0px 70px 70px 0px;
        border-radius: 0 0 0 40px;
        height: 70px;
        width: 70px;
    }

    .user-profile-bulb img {
        position: relative;
        bottom: 0;
        left: 10px;
        height: 120px;
    }

    .user-profile-content .profile-sec img {
        width: 80px;
        height: 80px;
        object-fit: cover;
        margin-right: 15px;
    }

    .past-activit-one img {
        height: 155px;
        width: 125px;
        border-radius: 20px 0px 0 20px;
        object-fit: cover;
        margin-right: 5px;
    }

    .past-activit-one .date-top-right {
        width: 162px;
        font-size: 12px;
        padding: 15px 10px 15px 0px;
    }

    .profile-bugs img {
        width: 30px;
        margin-right: 0;
        height: 30px;
    }

    .past-activit-one h3 {
        font-size: 16px;
        line-height: 22px;
    }

    .past-activit-one span {
        display: block;
    }

    .CareerLibrary-Block {
        height: auto;
    }

    .CareerLibrary-Block p {
        height: auto;
        -webkit-line-clamp: 10;
    }

    .tab-content-development .nav-tabs .nav-link {
        display: flex;
        line-height: 18px;
        padding: 15px 0px 12px 0px;
        width: auto;
        min-width: fit-content;
        border-radius: 16px 15px 0 0;
        align-items: center;
        gap: 10px;
        text-align: left !important;
        margin-right: 20px;    
    }

    .tab-content-development .nav-tabs .nav-link img {
        display: block;
        margin: 0 auto 0px;
    }

    .show-more-less {
        font-weight: 600;
        border: 2px solid gray;
        width: 140px;
        margin: 20px auto 15px;
        padding: 8px 0px;
        border-radius: 14px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        justify-items: center;
        text-align: center;
        font-size: 16px;
    }

    .tab-content-development .nav-tabs .nav-link.active {
        background-color: transparent;
        border-bottom: 2px solid #369FFF;
        top: -1px;
    }

    .CareerOptions-Tabs-type2 .tab-header h5 {
        margin-bottom: 32px;
        font-size: 20px;
        font-weight: 700;
        color: #001E3A;
        width: 44%;
    }

    .mobile-v2leaderbulp-img{
        max-width: 60% !important;
    }
    .counsellor-view {
        min-height: 240px;
        margin-bottom: 20px;
        padding: 25px 25px;
    }

    .counsellor-view span {
        margin-bottom: 5px;
        font-size: 34px;
    }

    .counsellor-view h3 {
        margin-bottom: 5px;
    }

    .counsellor-view:after {
        width: 175px;
        height: 105px;
    }

    .counsllor-view-list {
        height: 40px;
    }

    .counselling-view {
        padding: 25px;
        min-height: 250px;
    }

    .counselling-view .box-heading {
        font-size: 18px;
    }

    .counselling-view .user-box-con h4 {
        font-size: 18px;
    }

    .counselling-view .user-box-con p {
        font-size: 14px;
    }

    .counselling-view .date-know-more p {
        font-size: 14px;
        font-weight: 600;
    }

    .counselling-view .date-know-more a {
        font-size: 16px;
        padding: 5px 10px;
    }

    .counselling-view .box-heading span {
        padding: 6px 12px;
        margin: 0 20px;
        font-size: 14px;
    }

    .counsellor-analytics-heading-dates {
        display: block;
    }

    .counsellor-analytics-heading-dates h2 {
        margin-bottom: 12px;
        font-size: 22px;
    }

    .counsellor-analytics {
        margin: 25px 0 0 0;
    }

    .date-know-more img {
        width: 16px;
        height: 16px;
        margin-right: 4px;
    }

    .personality-assesment h2 {
        font-size: 18px;
        margin-bottom: 20px;
    }

    .personality-assesment h3 {
        font-size: 30px;
    }

    .personality-assesment .blend-img-counslr {
        width: 40px;
        height: 42px;
    }

    .personality-assesment .progress {
        margin-top: 10px;
    }

    .personality-assesment h4 {
        font-size: 18px;
        margin: 15px 0 25px 0;
    }

    .personality-assesment {
        min-height: 260px;
    }

    .c-pie-chart-data {
        margin-top: 0px;
    }

    .counsellor-types-students {
        padding: 25px 25px;
    }

    .c-type-student h3 {
        font-size: 16px;
    }

    .c-type-student .c-view-list {
        font-size: 16px;
    }
    .c-search-header .c-add-btn {
        font-size: 0;
        padding: 10px 10px;
        width: 52px;
        text-align: center;
    }

    .c-search-header .c-add-btn img {
        margin-left: 0px;
        top: 0px;
    }

    .c-search-header .c-filter {
        padding: 12px 6px;
        margin: 0px 10px;
    }

    .model-toggle-pop .form-check-label {
        line-height: 30px !important;
        font-size: 12px !important;
    }    

    .counsellor-home {
        margin: 40px 0 0px 0;
    }

    .model-toggle-pop .form-switch {
        width: 146px;
    }

    .model-toggle-pop {
        display: block;
    }

    .model-toggle-pop p {
        margin: 0;
        font-size: 14px;
        margin-left: 5px;
        position: absolute;
        right: 10px;
        top: 15px;
    }

    .toggle-time-select {
        margin-top: 12px;
    }

    .c-search-header .c-search input {
        padding: 10px 30px 10px 35px;
        font-size: 14px;
    }

    .c-search-header .c-search:before {
        width: 16px;
        height: 16px;
        left: 12px;
    }

    .c-table-user-list {
        padding: 25px 10px;
    }

    .c-table-user-list .c-user-rane h3 {
        font-size: 15px;
    }

    .c-table-user-list .c-user-rane p {
        font-size: 12px;
        line-height: 12px;
    }

    .c-check-callout {
        width: 12px;
        height: 12px;
        right: 18px;
        top: 45px;
    }

    .c-table-user-list .c-user-rane img {
        width: 36px;
        height: 36px;
        margin-right: 10px;
    }

    .c-add-student .modal-header {
        padding-top: 15px;
    }

    .c-add-student .modal-body {
        padding: 10px 20px 40px 20px;
    }

    .c-add-submit {
        margin: 25px auto 0;
    }
    .schedulingDetails.Active, .schedulingDetails  {
        text-align: center;
        padding: 10px;
        border-radius: 14px;
    }

    .scheduled-time-day h3 {
        font-size: 16px;
    }

    .CallScheduled_With h5{
        font-size: 12px;
        text-align: left;
    }

    .scheduler_img img {
        object-fit: cover;
        height: 35px;
        width: 35px;
        overflow: hidden;
        margin-right: 0px;
        border-radius: 50%;
    }
    
    .padding-mobile-none{
        padding: 0 !important;
    }

    .scheduler_img {
        margin-right: 0;
        margin-bottom: 0px;
        height: 44px;
        width: 44px;
    }

    .call_block {
        justify-content: space-around;
    }
    .scheduled-time-day, .CallScheduled_With, .call_block {
        margin-bottom: 0px;
        min-width: auto;
        width: auto;
        gap: 8px;
    }

    .CallScheduled_With span {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        text-align: left;
    }

    .call_block button{
        padding: 4px 2px;
        width: 70px;
        border-radius: 8px;
        font-size: 12px;
    }
    
    .sec-heading {
        display: inline-block;
        margin: 5px 0;
        text-align: center;
    }
    .sec-heading h3 {
        margin-bottom: 20px;
    }
    
    .mobile-logo-new{
        display: block !important;
        background-image: url(../images/logo.png) !important;
        width: 200px;
        height: 70px;
        background-size: contain !important;
        background-repeat: no-repeat !important;
        margin-bottom: 20px !important;
        margin-top: 0 !important;
    }

    .counsellor-student-list {
        margin: 50px 0 0px 0;
    }

    .leaders-content p {
        padding: 0 15px;
    }

    .tab-content-development .nav-tabs {
        border: none;
        background-color: #ffffff;
        flex-flow: nowrap;
        overflow: auto;
        align-items: flex-end;
        display: flex;
        flex-direction: unset !important;
        overflow-y: hidden;
    }

    .CareerOptions-Tabs-type2 .Textbtn-more {
        margin-top: 10px;
    }

}

@media screen and (max-width: 576px) {

    .civil_engineering_JobType {
        padding: 64px 24px;
    }

    .enginner-company-data .tab-content {
        padding: 40px 24px;
    }

    .eng-CompanyData-three-columns>* {
        width: calc(100% / 1);
        margin: auto;
    }

    .JobType-badges span.badge {
        width: 100%;
    }

    .enginner-job-type label {
        text-align: center;
        margin-bottom: 20px;
    }

    .rounded-rectangle {
        top: 72px;
        left: 70px;
        padding: 30px 60px;
        transform: rotate(-10deg);
    }

    .oval {
        padding: 30px 30px;
        position: relative;
        right: 0;
        left: 20px;
        margin: 0 auto;
    }

    .Path-to-enginnerTabs .tab-content .engCareerPathBlock div {
        display: flex;
        align-items: center;
    }

    .Path-to-enginnerTabs .tab-content .engCareerPathBlock p {
        margin: 0 20px;
        /* width: 100%; */
        text-align: left;
    }

    .engCareerPathBlock i {
        margin-bottom: unset;
        transform: rotate(90deg);
        margin: 0 36px 0;
    }

    .Path-to-enginnerTabs .tab-content .career-img {
        padding: 8px 8px;
        margin: 0;
    }

    .Path-to-enginnerTabs .tab-content {
        padding: 32px;
        border-radius: 0 0 24px 24px;
    }

    .Path-to-enginnerTabs button.active {
        border-radius: 30px 30px 0px 0px !important;
        height: auto;
    }

    .Path-to-enginnerTabs button {
        padding: 20px 16px;
    }

    .webinar-content {
        min-height: 80px;
    }

    .progress-path .step-one h3 {
        font-size: 12px;
        line-height: 16px;
    }

    .step-one:after {
        height: 3px;
        /* left: 40px; */
        top: 26%;
    }

    .progress-path .step-one img {
        width: 48px;
    }

    .progress-path .step-one {
        width: 53px;
    }

    .progress-path .step-one .check-progress {
        width: 30px;
        font-size: 20px;
        height: 21px;
        bottom: -15px;
    }

    .progress-path {
        display: flex;
        justify-content: space-between;
    }

    .non-active .bi-lock {
        font-size: 12px;
        top: 5px;
    }

    .progress-path .step-one h3 {
        font-size: 9px;
        line-height: 10px;
    }

    .progress-path .step-one .outer-progress {
        border: 2px solid #5DE27B;
    }

    .text-percentage {
        font-size: 9px !important;
    }

    .tab-content-development .nav-tabs .nav-link {
        font-size: 14px;
        text-align: center;
    }

    .tab-content-development .tab-content {
        padding: 25px 5px;
    }

    .superpower-img {
        padding: 0 60px 0;
    }

    .explore-card {
        padding: 40px 0 20px 0;
    }

    .explore-card-content {
        padding: 0px 40px 30px 40px;
    }

    .leaders-content .btn {
        padding: 14px 38px;
        font-size: 16px;
    }

    .leaders-content span, .superpowerBlock p span {
        font-size: 40px;
    }

    .CareerOptions-Tabs-type2 ul.nav.card-header-tabs {
        display: inline-block;
    }

    .CareerOptions-Tabs-type2 .fixed-registerBlock {
        display: inline-block;
    }

    .fixed-registerBlock h4 {
        margin-bottom: 20px;
    }

}

@media screen and (max-width:480px) {

    .oval-triangle-block {
        justify-content: center;
        align-items: flex-end;
    }

    .Webinar-sec {
        padding: 60px 20px 0 20px;
        overflow: hidden;
    }

    .triangle {
        padding: 0 58px;
        left: -30px;
    }

    .oval {
        padding: 30px 25px;
        position: relative;
        right: 0;
        left: 20px;
        font-size: 23px;
        z-index: 9;
    }

    .rounded-rectangle {
        top: 86px;
        left: 40px;
        transform: rotate(-20deg);
    }

    .Superpowers-WebinarBlock heading {
        font-size: 40px;
    }

    .activityIcon-label {
        justify-content: space-between;
    }

    .activityIcon-label label {
        max-width: 180px;
        text-align: left;
        margin-left: 0;
    }

    .spacebug-popup .modal-body img {
        border-radius: 28px 28px 0px 0px;
        object-fit: cover;
        height: 160px;
        width: 100%;
        object-position: 0px -65px;
    }
}

@media screen and (max-width:430px) {
    .Webinar-sec {
        padding: 60px 20px 40px 20px;
    }

    .Superpowers-WebinarBlock p {
        font-weight: bold;
        margin: 30px 0 50px 0;
    }

    .ShapesBlock-right {
        display: flex;
        position: relative;
        right: 0;
        margin: 0 auto;
        text-align: center;
        flex-direction: column-reverse;
    }

    .oval {
        left: unset;
        right: unset;
        padding: 20px 45px;
    }

    .oval-triangle-block {
        display: flex;
        flex-direction: column-reverse;
    }

    .rounded-rectangle {
        transform: rotate(0deg);
        top: 0;
        margin: 0 auto;
        left: unset;
        right: unset;
    }

    .triangle {
        left: unset;
        margin: 0 auto;
    }

    .activityIcon-label label {
        max-width: 100%;
        text-align: left;
        margin-left: 0;
        margin-top: 10px;
    }

    .activityIcon-label {
        justify-content: space-between;
        flex-direction: column;
        align-items: flex-start;
    }

    .superpower-img {
        padding: 0 40px 0;
    }
}

@media screen and (max-width: 425px) {
    .activityDetails {
        padding: 60px 24px 24px 24px;
    }
    .date-time {
        display: block;
        line-height: 38px;
    }
}



@media screen and (max-width:375px) {
    .superpowerBlock p {
        font-size: 18px;
    }

    .superpowerBlock p span {
        font-size: 36px;
    }

    .enginnering-career-detail heading {
        font-size: 30px;
    }

    .Path-to-enginnerTabs .nav-justified .nav-item .nav-link {
        width: 100%;
    }

    .Path-to-enginnerTabs button.active {
        background: #369FFF !important;
    }

    .Path-to-enginnerTabs ul#pills-tab {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }

    .Eng-college-name, .Eng-college-name div {
        flex-direction: column;
        text-align: center;
    }

    .Eng-college-name p {
        margin: 20px 16px;
    }

    .civil_engineering_JobType heading {
        font-size: 35px;
    }

   
}